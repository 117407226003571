import Layout from '@/components/layout';
import React, { useState } from 'react';
import Icon from 'supercons';
import { Formik, Field, Form } from 'formik';
import { getButtonProps, Statuses } from '@/model/statuses';

interface Values {
  name: string;
  email: string;
  message: string;
}

export default function Contact() {
  const [status, setStatus]: [Statuses | null, any] = useState(null);

  function encode(data: Record<string, any>) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return (
    <Layout>
      <h1 className="py-8 text-4xl font-bold md:text-5xl text-center">
        Get in Touch
      </h1>

      <div className="container prose pb-48">
        <p>
          Got an idea for a class, a teacher you think we should connect with, or anything else you want to discuss?
        </p>

        <Formik
          initialValues={{ email: '', name: '', message: '' }}
          onSubmit={async (values: Values, actions) => {
            setStatus(Statuses.Sending);

            fetch('/', {
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
               body: encode({ 'form-name': 'contact', ...values })
            })
            .then(() => {
                setStatus(Statuses.Success);
                actions.resetForm()
              })
              .catch(() => {
                setStatus(Statuses.Error);
              });
          }}>
            <Form
              netlify-honeypot="bot-field"
              data-netlify={true}
              name="contact"
              className={'font-body flex flex-col relative items-center rounded-sm py-8 mb-24'}>
              <Field
                className={'px-2 py-2 pr-8 w-full rounded-sm text-charcoal bg-white border-charcoal border-2 relative flex-grow-1'}
                id="email"
                name="email"
                placeholder="Enter Your Email"
                type="email"
                required={true} />

              <Field
                className={'my-4 px-2 py-2 pr-8 w-full rounded-sm text-charcoal bg-white border-charcoal border-2 relative flex-grow-1'}
                id="name"
                name="name"
                placeholder="Enter Your Name"
                type="text"
                required={true} />

              <Field
                as="textarea"
                className={'my-4 px-2 py-2 pr-8 w-full rounded-sm text-charcoal bg-white border-charcoal border-2 relative flex-grow-1'}
                id="message"
                name="message"
                placeholder="Message"
                rows="10"
                type="text"
                required={true} />

            <button
              className={`
                ${`transition-all rounded-sm pl-12 pr-4 h-12 font-title py-4 mt-4 text-offwhite flex justify-between items-center` }
                ${getButtonProps(status).classes}
              `}
              type="submit">
              <span className="pr-8">{getButtonProps(status, 'Submit').text}</span>
              <Icon
                glyph={getButtonProps(status).icon}
                size={32} />
            </button>

            {status === Statuses.Error && (
              <div className={'text-sm font-normal px-4 py-2 absolute -bottom-4 rounded-sm'}>
                Something went wrong. Let us know at andrew.haupt@hey.com.
              </div>
            )}
            </Form>
        </Formik>
      </div>
    </Layout>
  );
}
